@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px 0px;
  font-family: Avenir;
  max-width: 1080px;
  margin: 0 auto;
  margin-top: 24px;

  .offlineLocationHeader {
    display: flex;
    justify-content: space-between;

    .backToLink {
      display: inline-flex;
      align-items: center;
      gap: 0px 12px;
      font-size: 14px;
      font-style: normal;
      font-weight: 800;
      line-height: 22px; /* 157.143% */
      letter-spacing: 0.25px;
      padding: 9px 16px;
      border-radius: 8px;
      color: #423DCF;
      border: 2px solid #D9DBE9;
      text-decoration: none;
    }
  }

  .offlineLocationCourses {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(261px, 1fr));
    justify-content: flex-start;
    gap: 12px;
  }

  .loadMoreWrapper {
    display: flex;
    justify-content: center;
    padding: 30px 0px;

    .loadMore {
      display: inline-flex;
      align-items: center;
      gap: 0px 16px;
      padding: 10px 20px;
      color: #423DCF;
      background-color: transparentize($color: #423DCF, $amount: 0.80);
      border-radius: 5px;
      font-size: 18px;
      font-weight: 600;
    }
  }
}
