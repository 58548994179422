@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px 0px;
  font-family: Avenir;
  max-width: 1080px;
  margin: 0 auto;
  margin-top: 24px;
  margin-bottom: 50px;

  @media only screen and (max-width: 1079px) {
    padding: 0px 15px;
  }

  .sliderSection {
    display: flex;
  }

  .offlineLocationWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px 0px;

    .offlineLocationHeader {
      display: flex;
      justify-content: space-between;

      .offlineLocationTitle {
        color: #2f2f2f;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
      }

      .offlineLocationViewAll {
        color: #423dcf;
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        letter-spacing: 0.038px;
        text-decoration: none;
      }
    }

    .offlineLocationCourses {
      display: grid;
      grid-template-columns: repeat(auto-fit, 261px);
      grid-auto-rows: 1fr;
      justify-content: flex-start;
      gap: 12px;

      @include respond(mobile) {
        grid-template-columns: 1fr;
      }
    }
  }
}
